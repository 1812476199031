import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/quillEditor";
import VueMask from "v-mask";


import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
import pt from './locales/pt.json';
import en from './locales/en.json';
import es from './locales/es.json';
function getLocaleFromURL() {
  const url = window.location.hostname;
  if (url.endsWith('.br')) {
    return 'pt';
  } else if (url.endsWith('.py')) {
    return 'es';
  } else {
    return 'pt';
  }
}
const i18n = new VueI18n({
  locale: getLocaleFromURL(),
  fallbackLocale: 'pt',
  messages: {
    pt,
    en,
    es,
  },
});


Vue.config.productionTip = false;
Vue.use(VueMask);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
