<template>
  <div>
    <!-- Bolinha do chat -->
    <div
      class="chat-toggle"
      @click="toggleChat"
      :style="{ bottom: `${position.y}px`, right: `${position.x}px` }"
      ref="toggleButton"
      @mousedown="startDrag"
    >
      💬
    </div>

    <!-- Janela do chat -->
    <div v-if="isOpen" class="chat-window" ref="chatWindow">
      <div class="chat-header">
        <span>Suporte</span>
        <button @click="toggleChat">×</button>
      </div>
      <div class="chat-body">
        <div class="messages">
          <div v-for="message in messages" :key="message.id" :class="['message', message.iduser == user.id ? 'user-message' : 'agent-message']">
            <strong>{{ message.user }}:</strong> {{ message.text }}
          </div>
        </div>
      </div>
      <div class="chat-footer">
        <div v-if="!nameEntered" class="name-entry">
          <input type="text" v-model="userName" @keyup.enter="enterName" placeholder="Seu nome" />
          <button @click="enterName">Iniciar</button>
        </div>
        <div v-else class="message-entry">
          <input
            type="text"
            v-model="newMessage"
            @keyup.enter="sendMessage"
            :readonly="ticket.closedAt != null"
            placeholder="Digite sua mensagem"
          />
          <button @click="sendMessage" :disabeld="ticket.closedAt != null">Enviar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");
const authorization = { headers: { authorization: token } };

export default {
  data() {
    return {
      isOpen: false,
      position: { x: 20, y: 20 },
      isDragging: false,
      dragOffset: { x: 0, y: 0 },
      user: {
        id: 32741, 
        role: 6, 
        username: 'Cliente'
      }, 
      userName: '',
      nameEntered: false,
      newMessage: '',
      ticket: {},
      messages: [],
      mqttClient: null
    };
  },
  mounted() {
    // Carregue o script mqtt.min.js
    const script = document.createElement('script');
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/mqtt/4.2.8/mqtt.min.js';
    document.head.appendChild(script);
    this.decodeToken();
  },
  beforeDestroy() {
    if (this.mqttClient) {
      this.mqttClient.end();
    }
  },
  methods: {
    toggleChat() {
      this.isOpen = !this.isOpen;
    },
    startDrag(event) {
      this.isDragging = true;
      this.dragOffset.x = event.clientX - this.$refs.toggleButton.getBoundingClientRect().right;
      this.dragOffset.y = event.clientY - this.$refs.toggleButton.getBoundingClientRect().bottom;
      /*document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDrag);*/
    },
    onDrag(event) {
      if (this.isDragging) {
        this.position.x = window.innerWidth - event.clientX + this.dragOffset.x;
        this.position.y = window.innerHeight - event.clientY + this.dragOffset.y;
      }
    },
    stopDrag() {
      this.isDragging = false;
      /*document.removeEventListener('mousemove', this.onDrag);
      document.removeEventListener('mouseup', this.stopDrag);*/
    },
    async enterName() {
      if (this.userName.trim()) {
        this.nameEntered = true;

        var res = await axios.post(`${url}/tickets`, this.ticket, authorization);
        this.ticket.id = res.data.success.id;

        this.connectMQTT();

        this.newMessage = `Nova conversa iniciada por ${this.userName}`;
        await this.sendMessage();
      }
    },
    async sendMessage() {
      if (this.newMessage.trim()) {
        var message = {
            idticket: this.ticket.id,
            iduser: this.ticket.idusers,
            idusertype: this.ticket.idusertype,
            message: this.newMessage
        };
        await axios.post(`${url}/ticketMessages`, message, authorization);

        this.getTicketMessages();

        this.newMessage = '';
      }
    },
    getTicketMessages() {
        axios
            .get(`${url}/tickets/${this.ticket.id}`, {
            headers: {
                authorization: token,
            },
            })
            .then((res) => {
                // Aqui você processa os dados retornados para formatar como necessário
                const data = res.data;

                data.forEach(item => {
                    // Verifica se a mensagem já existe no ticket
                    const existingMessage = this.messages.some(msg => msg.id == item.idmessage);
                    if (!existingMessage) {
                        this.messages.push({
                            id: item.idmessage,
                            user: item.user,
                            text: item.text,
                            iduser: item.iduser
                        });
                    }

                    this.ticket.closedAt = item.closedAt;
                });

            })
            .catch((err) => {
            console.error('Erro ao buscar mensagens do ticket:', err);
        });
    },
    connectMQTT() {
        // Verifique se MQTT está disponível
      if (window.mqtt) {
        const mqtt_broker = process.env.VUE_APP_MQTT_BROKER;
        const options = {
            username: process.env.VUE_APP_MQTT_USER,
            password: process.env.VUE_APP_MQTT_PASSWORD
        };
        // Conectar ao broker MQTT via WebSocket
        this.mqttClient = window.mqtt.connect(mqtt_broker, options);

        this.mqttClient.on('connect', () => {
          //console.log('Conectado ao broker MQTT');

          // Inscreva-se no tópico do ticket
          const topic = `tickets/${this.ticket.id}/messages/new`;
          this.mqttClient.subscribe(topic, (err) => {
            if (!err) {
              //console.log(`Inscrito no tópico: ${topic}`);
            } else {
              console.error(`Erro ao se inscrever no tópico: ${topic}`, err);
            }
          });
        });

        this.mqttClient.on('message', (topic, message) => {
            console.log(topic, message);
            this.getTicketMessages();
        });

        this.mqttClient.on('error', (err) => {
          console.error('Erro MQTT:', err);
        });
      } else {
        console.error('Biblioteca MQTT não carregada.');
      }
    },

    loadScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Erro ao carregar o script: ${src}`));
        document.head.appendChild(script);
      });
    },
    async decodeToken() {
      try {
        await this.loadScript('https://cdn.jsdelivr.net/npm/jwt-decode@3.1.2/build/jwt-decode.min.js');
        const token = localStorage.getItem("accessToken");
        if (token) {
          try {
            const decodedToken = window.jwt_decode(token);
            this.user = decodedToken;
          } catch (error) {
            console.error("Erro ao decodificar o token:", error);
          }
        }
        this.ticket.idusers = this.user.id;
        this.ticket.idusertype = this.user.role;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.chat-toggle {
  width: 50px;
  height: 50px;
  background-color: #007bff;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.chat-window {
  position: fixed;
  bottom: 70px;
  right: 20px;
  width: 350px;
  height: 500px;
  background-color: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 999;
}

.chat-header {
  background-color: #007bff;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-body {
  padding: 10px;
  flex: 1;
  overflow-y: auto;
}

.chat-footer {
  padding: 10px;
  background-color: #f1f1f1;
  display: flex;
  gap: 10px;
  border-top: 1px solid #ddd;
}

input[type="text"] {
  flex: 1;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.name-entry,
.message-entry {
  display: flex;
  align-items: center;
  gap: 10px;
}

.messages {
  padding: 10px 0;
}

.message {
  margin-bottom: 8px;
  padding: 5px 10px;
  border-radius: 10px;
  max-width: 80%;
  word-wrap: break-word;
}

.user-message {
  background-color: #e0e0e0;
  text-align: left;
  margin-left: 10px;
  align-self: flex-start;
}

.agent-message {
  background-color: #007bff;
  color: white;
  text-align: right;
  margin-right: 10px;
  align-self: flex-end;
}

.message strong {
  display: block;
}
</style>
