import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#3f51b5",
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#b71c1c",
        pink: "#FD80CD",
        cyan: "#1CFEE8",
        yellow: "#F9C228",
        darkpink: "#E245A6",
        darkcyan: "#089790",
        darkyellow: "#CF9C0E",
        blue: "#197CFF",
        darkblue: "#1670E8",
        backcolor: "#dcebff",
        darkcolor: "#0E4887",
        green: "#468C00",
        red: "#E50000",
        black: "#202020",
        cookie: "#FFA842",
        darkcookie: "#C93F2D",
      },
    },
  },
});
